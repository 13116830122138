<template>
  <div class="panel-body bg-white">
    <div class="row">
      <div class="panel panel-inverse col-lg-5" data-sortable-id="form-stuff-1">
        <!-- panel-body begin -->
        <div class="panel-body">
          <div style="position: absolute; right: 20px; top: 80px; z-index: 99">
            <button class="btn btn-primary" @click="SearchClick()">
              <i class="fas fa-search"> 查询</i>
            </button>
          </div>
          <h1 class="page-header">
            网格长轨迹图 <small>(请选择姓名和时间)</small>
          </h1>
          <hr />
          <div class="row form-group">
            <label class="col-form-label text-right" width="100px">
              网格长:</label
            >
            <div class="col-lg-5 col-md-5">
              <v-select
                :show="false"
                @input="gridIdSelect"
                v-model="gridId"
                :reduce="(gridheadEquipmentDict) => gridheadEquipmentDict.value"
                :options="gridheadEquipmentDict"
              ></v-select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-form-label text-right" width="100px"
              >&nbsp;&nbsp;&nbsp;日期:</label
            >
            <div class="col-lg-5 col-md-5">
              <date-picker
                input-class="form-control bg-white"
                v-model="GridDay"
                :config="options"
              ></date-picker>
            </div>
            <div
              class="col-lg-5 col-md-5"
              style="position: absolute; right: 20px; top: 132.5px; z-index: 99"
            >
              <date-picker
                input-class="form-control bg-white"
                v-model="EndDay"
                :config="options"
              ></date-picker>
            </div>
          </div>
          <div class="row form-group">
            <div class="table-responsive">
              <table
                class="table table-bordered table-invoice table-striped"
                style="min-width: 700px"
              >
                <thead>
                  <tr>
                    <th class="text-center" width="100px">序号</th>
                    <th class="text-left" width="200px">时间</th>
                    <th class="text-left" width="500px">地点</th>
                    <th class="text-left" width="200px">备注</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item["GridDay"].slice(0, 10) }}</td>
                    <td>{{ item["address"] }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content col-lg-7">
        <div
          id="container"
          :center="{ point }"
          :style="{ height: windowHeight }"
          class="map"
          tabindex="0"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { businessApi } from "@/api/system/businessApi";

//防盗水印
// import watermark from "../plugins/watermark/index";
export default {
  data() {
    return {
      AMap: null,
      map: null,
      polyline: null,
      tableData: [],
      lineArr: [],
      Cameradata: [
        // {
        //   address: "浙江省宁波市鄞州区东钱湖镇钱湖西路",
        //   id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
        //   location: "121.634535, 29.789727",
        //   time: "2022-3-23 12:25",
        //   safetyLevel: 2,
        //   state: 2,
        // },
        // {
        //   address: "浙江省宁波市鄞州区东钱湖镇莫枝北路",
        //   id: "3a01a225-55d2-fe4b-e8fd-4b348891eb6f",
        //   location: "121.630227, 29.806277",
        //   time: "2022-3-23 13:50",
        //   safetyLevel: 0,
        //   state: 2,
        // },
        // {
        //   address: "浙江省宁波市鄞州区东钱湖镇天池路",
        //   id: "3a01a225-55d2-fe4b-e8fd-4b348891eb6f",
        //   location: "121.611388,29.81603",
        //   time: "2022-3-23 14:56",
        //   safetyLevel: 0,
        //   state: 2,
        // },
        // {
        //   address: "浙江省宁波市鄞州区东钱湖镇白石北路",
        //   id: "3a01a225-55d2-fe4b-e8fd-4b348891eb6f",
        //   location: "121.605359,29.806283",
        //   time: "2022-3-23 17:23",
        //   safetyLevel: 0,
        //   state: 2,
        // },
      ],
      gridheadEquipmentDict: [], //网格长
      gridId: "",
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      GridDay: new Date(),
      EndDay: "",
      // loca: null,
      cluster: null,
      markers: [],
      aMap: null,
      loca: null,
      windowHeight: "",
      point: [121.651287, 29.771813],
      geoData: {
        data: [],
        all: [],
        geoGreen: [],
        geoRed: [],
        geoYellow: [],
      },
      breath: {
        green: null,
        red: null,
        yellow: null,
      },
    };
  },
  name: "Home",
  created() {
    //如未登录则跳转到登录页面，因Home为默认打开页面，就算是刷新非Home页面也会触发
    let hasLogin = this.$store.getters.hasLogin;
    if (!hasLogin) {
      this.$router.push({ path: "/login" });
    }
    //添加防盗水印
    // let user = this.$store.getters.userInfo;
    // if (user != undefined) {
    //   watermark.set(user.username, new Date());
    // }
    this.windowHeight = document.documentElement.clientHeight - 64 + "px";
    window.openDeviceDetail = this.openDeviceDetail;
    window.openProjectDetail = this.openProjectDetail;
  },
  mounted() {
    this.GetUsersList(); //读取网格长字典
    this.geoData.geoGreen = this.Cameradata.filter((item) => {
      return item.state == 2;
    });
    this.geoData.geoRed = this.Cameradata.filter((item) => {
      return item.state == 2;
    });
  },
  methods: {
    //读取所有企业点位数据
    // getMapLocation(icount) {
    //   let that = this;
    //   that.geoData.all = [];
    //   const params = {
    //     SafetyLevel: -1,
    //     MaxResultCount: icount,
    //   };
    //   businessApi.GetMapLocation(params).then((res) => {
    //     for (let i = 0; i < res.data.items.length; i++) {
    //       let element = res.data.items[i];
    //       let geo = {
    //         location: element.location,
    //         id: element.id,
    //         name: element.name,
    //         address: element.address,
    //       };

    //       // 这段代码是模拟企业状态，正式使用根据实际数据显示
    //       if (i < 10) {
    //         geo.state = 0;
    //       } else if (i < 50) {
    //         geo.state = 1;
    //       } else {
    //         geo.state = 2;
    //       }

    //       that.geoData.all.push(geo);
    //       that.geoData.data = that.geoData.all;
    //       that.geoData.geoGreen = that.geoData.all.filter((item) => {
    //         return item.state == 2;
    //       });
    //       that.geoData.geoRed = that.geoData.all.filter((item) => {
    //         return item.state == 0;
    //       });
    //       that.geoData.geoYellow = that.geoData.all.filter((item) => {
    //         return item.state == 1;
    //       });
    //     }
    //     that.createMap(that.geoData.all);
    //   });
    // },
    createMap(data) {
      let that = this;
      AMapLoader.load({
        key: "5794a2a043de715b4d37dd92f0ba64b6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["Map3D", "AMap.MarkerClusterer"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2", // Loca 版本，缺省 1.3.2
        },
      }).then((AMap) => {
        that.AMap = AMap;
        that.setMapData(data);
      });
    },
    //向地图添加数据点
    setMapData(data) {
      let that = this;
      if (that.aMap != undefined) {
        that.aMap.destroy();
      }
      that.aMap = new that.AMap.Map("container", {
        zoom: 10,
        center: [121.651287, 29.771813], //初始化地图中心点
        pitch: 40,
        mapStyle: "amap://styles/637e827d3ba2b1e2b5030a354e47f5ef",
        viewMode: "3D",
        //  features: ['bg', 'road'],
      });
      //创建 Loca 实例
      if (that.loca != undefined) {
        that.loca.destroy();
      }

      let layer = new Loca.RoundPointLayer({
        map: this.aMap,
        fitView: true,
        eventSupport: true,
      });

      layer.setData(data, {
        lnglat: "location",
      });

      layer.setOptions({
        style: {
          // 圆形半径，单位像素
          radius: 7,
          // 填充颜色
          color: "#08fb1a",
        },
        // 描边颜色
        // borderColor: '#5DFBF9',
        // 描边宽度，单位像素
        borderWidth: 1,
        // 透明度 [0-1]
        opacity: 0.6,
      });

      new this.AMap.InfoWindow({
        closeWhenClickMap: true,
      });

      layer.on("click", function (ev) {
        // 当前元素的原始数据
        let rawData = ev.rawData;

        that.openInfo(rawData);
      });
      layer.render();
    },

    //在指定位置打开信息窗体
    openInfo(rawData) {
      //构建信息窗体中显示的内容
      let info = [];
      let infoWindow;
      let zuobiao = rawData.location.split(",");
      let point = [parseFloat(zuobiao[0]), parseFloat(zuobiao[1])];
      info.push(
        '<div class="media media-sm"><i class="  p-t-10 media-left fas fa-4x fa-fw m-r-10 fa-map-marker-alt text-green"></i> <div class="media-body">' +
          '<p></p><h5 class="media-heading">' +
          rawData.time +
          "</h5><p>位置：" +
          rawData.address
      );
      infoWindow = new this.AMap.InfoWindow({
        content: info.join(""), //使用默认信息窗体框样式，显示信息内容
      });
      infoWindow.open(this.aMap, point);
    },

    openProjectDetail(id) {
      this.$router.push({ path: "/Test?id=" + id });
    },
    //所属网格选择框返回值
    gridIdSelect(id) {
      console.log("gridIdSelect", id);
      this.gridId = id;
    },
    //读取网格长数据字典
    GetUsersList() {
      let that = this;
      let options = [];
      that.gridheadEquipmentDict = [];
      businessApi
        .GetUsersList({
          SkipCount: 1,
          MaxResultCount: 100,
          Sorting: "",
          Filter: "",
          RoleId: "2",
        })
        .then((res) => {
          res.data.items.forEach((element) => {
            options.push({
              value: element.id,
              label: element.name,
            });
          });
          that.gridheadEquipmentDict = options;
          // 格式化初始日期
          let date = {
            year: this.GridDay.getFullYear(),
            month: this.GridDay.getMonth() + 1,
            date: this.GridDay.getDate(),
          };
          this.GridDay = date.year + "-" + date.month + "-" + date.date;
          this.EndDay = date.year + "-" + date.month + "-" + date.date;
          console.log(this.GridDay);
          this.createMap([]);
        });
    },

    //查询按钮
    SearchClick() {
      if (this.gridId == "") {
        this.$swal({
          // title: "Are you sure?",
          icon: "warning",
          text: "请选择网格长！",
          type: "info",
          showCancelButton: true, //确定按钮
          buttonsStyling: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
          cancelButtonClass: "btn btn-default", //按钮类型设置
        });
        return;
      }
      let that = this;
      let options = [];
      let options1 = [];
      that.Cameradata = [];
      // that.lineArr = [];
      businessApi
        .Getsitude({
          SkipCount: 1,
          MaxResultCount: 100,
          Sorting: "",
          Filter: "",
          UserId: that.gridId,
          StartTime: that.GridDay,
          EndTime: that.EndDay + " 23:59:59",
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          if (res.data.items) {
            res.data.items.forEach((element) => {
              options.push({
                GridDay: element.creationTime.slice(0, 16),
                address: element.address,
              });

              options1.push([element.longitude, element.latitude]);

              that.Cameradata.push({
                address: element.address,
                id: element.id,
                location: element.longitude + "," + element.latitude,
                time: element.creationTime.slice(0, 19),
                safetyLevel: 2,
                state: 2,
              });
            });
          }
          that.tableData = options;
          that.lineArr = options1;
          // this.lineArr = [
          //   [121.62122937991184, 29.856814700862373],
          //   [121.621135, 29.856784],
          // ];
          // [
          //   [121.634535, 29.789727],
          //   [121.634733, 29.78915],
          //   [121.632893, 29.789131],
          //   [121.632355, 29.789117],
          //   [121.629347, 29.787972],
          //   [121.628591, 29.790049],
          //   [121.62769, 29.794881],
          //   [121.634551, 29.796655],
          //   [121.634921, 29.797856],
          //   [121.634792, 29.798764],
          //   [121.634653, 29.799565],
          //   [121.630227, 29.806277],
          //   [121.632513, 29.802885],
          //   [121.629713, 29.807009],
          //   [121.627878, 29.816914],
          //   [121.61701, 29.818785],
          //   [121.612729, 29.819586],
          //   [121.611388, 29.81603],
          //   [121.610208, 29.813386],
          //   [121.605359, 29.806283],
          // ];
          this.setMapData(this.Cameradata);
          // 绘制轨迹
          new this.AMap.Polyline({
            map: this.aMap,
            path: this.lineArr,
            showDir: true,
            strokeColor: "#28F", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6, //线宽
            // strokeStyle: "solid"  //线样式
          });
        });
    },
  },
};
</script>
<style>
#container {
  left: 0%;
  top: 0px;
}
/* 去除高德地图logo */
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.table-responsive {
  min-height: 400px;
}
.table {
  max-height: 100%;

  overflow-x: auto;
  border: 1px solid #0c6c8b;
}
.router-alive {
  background-color: #ffffff;
}
</style>
